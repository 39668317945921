<template>
  <section>
    <b-form-row v-if="restrictAddSecurityMethodToMultipleCity">
      <b-col>
        <b-alert show variant="danger">{{ DISPLAY_MESSAGES.MULTIPLE_CITIES_ADD_SECURITY_METHOD_ERROR }}</b-alert>
      </b-col>
    </b-form-row>
    <template v-else>
      <b-form-row class="my-4 justify-content-between">
        <b-col lg="7" sm="7" cols="12">
          <div class="floating-input-field">
            <b-form-input
              id="form-input-search-security-methods"
              class="mb-2 mb-sm-1"
              v-model="filter"
              @input="currentPage = 1"
              placeholder=" "
              type="text"
            ></b-form-input>
            <label for="form-input-search-security-methods">Search security methods <BIconSearch class="input-search-icon" /></label>
          </div>
        </b-col>
        <b-col lg="5" sm="5" cols="12" class="mb-2 mb-sm-1 text-right">
          <b-button type="button" id="add-security-method-btn" v-activeBlur variant="primary" @click="navigate('add-security-method')"
            >Add security method<BIconPlus scale="1.5"
          /></b-button>
        </b-col>
      </b-form-row>
      <!-- Security methods table element -->
      <b-table
        :items="securityMethods"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @context-changed="setTableCache($event, 'security')"
        @row-dblclicked="onSecurityMethodRowDblclicked"
        show-empty
        @filtered="onFiltered"
        hover
        class="table-transition"
        id="security-methods-table"
        primary-key="id"
        :tbody-transition-props="transProps"
        :busy="securityMethodsLoading"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading...</strong>
          </div>
        </template>
        <template #cell(httpClientType)="row">
          {{ getHttpClientTypeTitle(row.value) }}
        </template>
        <template #cell(isDefault)="row">
          {{ row.value ? 'Yes' : 'No' }}
        </template>
        <template #cell(actions)="row">
          <b-dropdown class="action-dropdown" no-caret variant="outline-secondary" right>
            <template #button-content>
              Actions
              <BIconChevronDown class="pt-1" variant="dark" scale="0.65" />
            </template>
            <b-dropdown-item @click="navigate(`edit-security-method/${row.item.id}`)">Edit</b-dropdown-item>
            <b-dropdown-item href="javascript:void(0)" @click="deleteSecurityMethodConfirmDailog(row.item)">Delete</b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <!-- Table options-->
      <b-form-row>
        <b-col sm="6" md="6" lg="3" cols="12" class="my-1">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="5"
            label-cols-md="6"
            label-cols-lg="4"
            label-cols-xl="3"
            label-cols="3"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="6" class="d-none d-lg-block"></b-col>
        <b-col sm="6" md="6" lg="3" class="my-1">
          <b-pagination
            v-if="!securityMethodsLoading"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-form-row>
      <ConfirmationModal
        :showModal="deleteSecurityMethodConfirmModal"
        :title="`CONFIRM`"
        :message="deleteSecurityMethodMessage"
        :isKey="deleteSecurityMethodKey"
        :isHtml="deleteSecurityMethodIsHtml"
        @onConfirm="deleteSecurityMethod"
        @closeConfirmModal="deleteSecurityMethodConfirmModal = false"
      />
    </template>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { BIconPlus, BIconSearch, BIconChevronDown } from 'bootstrap-vue'
import { DISPLAY_MESSAGES } from '@/utilities/constants'
import { useAxios } from '@/composables/useAxios'
import { ServiceUrls } from '@/utilities/serviceUrls'
import { tenantSpecificURL } from '@/utilities/utilities'
import { HTTPCLIENT_TYPE_TITLE_ENUMS } from '@/utilities/settings-constants'
import { useTableOptionsCache } from '@/composables/useTableOptionsCache'
export default {
  name: 'Security',
  setup() {
    const { isLoading: securityMethodsLoading, response: securityMethodsResponse, error: securityMethodsError, callApi: securiytMethodsApi } = useAxios()
    const { response: deleteSecurityMethodResponse, error: deleteSecurityMethodError, callApi: deleteSecurityMethodApi } = useAxios()
    const { filter, perPage, currentPage, sortBy, sortDesc, getTableCache, setTableCache } = useTableOptionsCache('security')
    const {
      isLoading: getAssociatedSecurityMethodNamesLoading,
      response: getAssociatedSecurityMethodNamesResponse,
      error: getAssociatedSecurityMethodNamesError,
      callApi: getAssociatedSecurityMethodNamesApi,
    } = useAxios()
    return {
      securityMethodsLoading,
      securityMethodsResponse,
      securityMethodsError,
      securiytMethodsApi,
      deleteSecurityMethodResponse,
      deleteSecurityMethodError,
      deleteSecurityMethodApi,
      getAssociatedSecurityMethodNamesLoading,
      getAssociatedSecurityMethodNamesResponse,
      getAssociatedSecurityMethodNamesError,
      getAssociatedSecurityMethodNamesApi,
      filter,
      perPage,
      currentPage,
      sortBy,
      sortDesc,
      getTableCache,
      setTableCache,
    }
  },
  data() {
    return {
      transProps: {
        name: 'flip-list',
      },
      deleteSecurityMethodConfirmModal: false,
      deleteSecurityMethodKey: false,
      deleteSecurityMethodIsHtml: false,
      deleteSecurityMethodMessage: '',
      selectedSecurityMethod: null,
      fields: [
        {
          key: 'methodName',
          label: 'Security method name',
          sortable: true,
        },
        {
          key: 'httpClientType',
          label: 'HttpClientType',
          sortable: true,
        },
        { key: 'isDefault', label: 'Default method', sortable: true },
        { key: 'actions', label: '' },
      ],
      totalRows: 1,
      pageOptions: [5, 10, 15, 25],
      sortDirection: 'asc',
      filterOn: ['methodName', 'httpClientType', 'isDefault'],
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
    }
  },
  components: {
    BIconPlus,
    BIconSearch,
    BIconChevronDown,
    ConfirmationModal: () => import('../../common/ConfirmationModal.vue'),
  },
  computed: {
    restrictAddSecurityMethodToMultipleCity() {
      return this.selectedTenant.length > 1 || this.selectedTenant.some((tenant) => tenant.tenantID === 0)
    },
    getHttpClientTypeTitle() {
      return (type) => {
        return HTTPCLIENT_TYPE_TITLE_ENUMS[type]
      }
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant,
      securityMethods: (state) => state.settings.securityMethods,
    }),
  },
  mounted() {
    this.getSecurityMethods()
  },
  methods: {
    navigate(url) {
      this.$router.push(url)
    },
    onSecurityMethodRowDblclicked(item) {
      this.navigate(`edit-security-method/${item.id}`)
    },
    async getSecurityMethods() {
      if (this.selectedTenant.length && !this.restrictAddSecurityMethodToMultipleCity) {
        this.$store.commit('settings/setSecurityMethods', [])
        await this.securiytMethodsApi({ method: 'get', url: tenantSpecificURL(ServiceUrls.getSecurityMethods) })
        if (this.securityMethodsResponse) {
          this.$store.commit('settings/setSecurityMethods', this.securityMethodsResponse)
        }
        if (this.securityMethodsError) {
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'SECURITY_METHODS_ERROR',
            type: 'danger',
          })
          this.totalRows = 0
        }
      }
    },
    async deleteSecurityMethodConfirmDailog(item) {
      this.selectedSecurityMethod = item
      this.deleteSecurityMethodIsHtml = false
      this.deleteSecurityMethodKey = true
      if (item.isDefault) {
        this.deleteSecurityMethodKey = true
        this.deleteSecurityMethodMessage = `DELETE_DEFAULT_SECURITY_METHOD`
        this.deleteSecurityMethodConfirmModal = true
      } else {
        await this.getAssociatedSecurityMethodNamesApi({ method: 'get', url: `${ServiceUrls.getAssociatedSecurityMethodNames}/${item.id}` })
        if (this.getAssociatedSecurityMethodNamesResponse) {
          if (this.getAssociatedSecurityMethodNamesResponse.length) {
            this.deleteSecurityMethodIsHtml = true
            this.deleteSecurityMethodKey = false
            this.deleteSecurityMethodMessage = `<p>This security is being used by the following items</p><ul>`
            this.getAssociatedSecurityMethodNamesResponse.forEach((item) => {
              this.deleteSecurityMethodMessage += `<li>${item}</li>`
            })
            this.deleteSecurityMethodMessage += `</ul>Are you sure you wish to delete it? The APIs will default to utilizing the default security method if one is configured.`
          } else {
            this.deleteSecurityMethodKey = true
            this.deleteSecurityMethodMessage = `DELETE_SECURITY_METHOD`
          }
          this.deleteSecurityMethodConfirmModal = true
        }
        if (this.getAssociatedSecurityMethodNamesError) {
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'ASSOCIATED_SECURITY_METHODS_ERROR',
            type: 'danger',
          })
        }
      }
    },
    async deleteSecurityMethod() {
      await this.deleteSecurityMethodApi({ method: 'post', url: `${ServiceUrls.deleteSecurityMethod}/${this.selectedSecurityMethod.id}` })
      if (this.deleteSecurityMethodResponse) {
        this.deleteSecurityMethodConfirmModal = false
        this.$store.commit('common/setCustomToastData', {
          message: false,
          key: 'DELETE_SECURITY_METHOD_SUCCESS',
          type: 'success',
        })
        this.getSecurityMethods()
      }
      if (this.deleteSecurityMethodError) {
        this.deleteSecurityMethodConfirmModal = false
        this.$store.commit('common/setCustomToastData', {
          message: false,
          key: 'FAILED',
          type: 'danger',
        })
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
    },
  },
  watch: {
    selectedTenant(newValue, oldValue) {
      this.getSecurityMethods()
    },
    securityMethods() {
      this.totalRows = this.securityMethods.length
      this.getTableCache('security')
    },
  },
}
</script>
<style lang="scss" scoped></style>
