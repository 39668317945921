export const HTTPCLIENT_TYPE_ENUMS = {
  SIMPLE_AUTH: 'SimpleAuthentication',
  OAUTH2: 'Oauth2Authentication'
}

export const HTTPCLIENT_TYPE_TITLE_ENUMS = {
  SimpleAuthentication: 'Simple Authentication',
  Oauth2Authentication: 'OAuth2 Authentication'
}

export const HTTPCLIENT_TYPES = [
  { title: 'Simple Authentication', value: HTTPCLIENT_TYPE_ENUMS.SIMPLE_AUTH },
  { title: 'OAuth2 Authentication', value: HTTPCLIENT_TYPE_ENUMS.OAUTH2 }
]

export const LINK_ACCOUNT_FORM_TABS = [
  {
    label: 'Configuration',
    value: 'Configuration',
    componentName: 'LinkAccountConfiguration',
    display: true,
    isVisited: false,
    hasErrors: false
  },
  {
    label: 'API',
    value: 'API',
    componentName: 'LinkAccountAPI',
    display: true,
    isVisited: false,
    hasErrors: false
  }
]

export const LINK_ACCOUNT_FIELD_TYPES = [
  'Text', 'Password', 'Date', 'Amount'
]
